@import "../../../../theme/index.module.scss";


.Wrapper {
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);

  @include bp($medium) {
    box-shadow: unset;
  }
}

.Mobile {
  @include bp($medium) {
    display: none;
  }
}

.Desktop {
  display: none;

  @include bp($medium) {
    display: block;
  }
}
